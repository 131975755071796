(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/draws/assets/javascripts/services/fetch-forecast.js') >= 0) return;  svs.modules.push('/components/marketplace-data/draws/assets/javascripts/services/fetch-forecast.js');
"use strict";


const {
  helpers
} = svs.components.marketplaceData.draws;
const {
  jupiter
} = svs.core;
const fetchForecast = (productId, drawNumber, callback) => {
  const forecastUrl = helpers.getForecastUrl(productId, drawNumber);
  jupiter.get(forecastUrl, response => {
    callback(undefined, helpers.normalizeForecast(response));
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceData.draws.services.fetchForecast', fetchForecast);

 })(window);