(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/draws/assets/javascripts/draws-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/draws/assets/javascripts/draws-actions.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  services
} = svs.components.marketplaceData.draws;
const {
  helpers
} = svs.components.marketplaceData.draws;
const {
  selectors
} = svs.components.marketplaceData;
const {
  productIds
} = svs.utils.products;
const FETCH_PRODUCTS_DRAWS = 'DRAWS/FETCH_PRODUCTS_DRAWS';
const FETCH_PRODUCTS_DRAWS_FAILURE = 'DRAWS/FETCH_PRODUCTS_DRAWS_FAILURE';
const FETCH_PRODUCTS_DRAWS_SUCCESS = 'DRAWS/FETCH_PRODUCTS_DRAWS_SUCCESS';
const fetchProductsDrawsSuccess = (draws, productName) => _objectSpread({
  type: FETCH_PRODUCTS_DRAWS_SUCCESS,
  productName
}, draws);
const FETCH_ALL_DRAWS_FAILURE = 'DRAWS/FETCH_ALL_DRAWS_FAILURE';
const FETCH_ALL_DRAWS_SUCCESS = 'DRAWS/FETCH_ALL_DRAWS_SUCCESS';
const FETCH_ALL_DRAWS = 'DRAWS/FETCH_ALL_DRAWS';
const FETCH_SINGLE_DRAW = 'DRAWS/FETCH_SINGLE_DRAW';
const FETCH_SINGLE_DRAW_SUCCESS = 'DRAWS/FETCH_SINGLE_DRAW_SUCCESS';
const FETCH_SPORTKRYSS = 'DRAWS/FETCH_SPORTKRYSS';
const FETCH_SPORTKRYSS_FAILURE = 'DRAWS/FETCH_SPORTKRYSS_FAILURE';
const FETCH_SPORTKRYSS_SUCCESS = 'DRAWS/FETCH_SPORTKRYSS_SUCCESS';
const fetchSingleDrawSuccess = (draw, productName, drawNumber) => _objectSpread({
  type: FETCH_SINGLE_DRAW_SUCCESS,
  productName,
  drawNumber
}, draw);
const FETCH_SINGLE_DRAW_FAILURE = 'DRAWS/FETCH_SINGLE_DRAW_FAILURE';
const fetchAllDrawsAction = products => dispatch => {
  dispatch({
    type: FETCH_ALL_DRAWS,
    products
  });
  services.fetchAllDraws(products, (error, productDraws) => {
    if (error) {
      dispatch({
        type: FETCH_ALL_DRAWS_FAILURE,
        error
      });
    } else {
      dispatch({
        type: FETCH_ALL_DRAWS_SUCCESS,
        productDraws,
        products
      });
    }
  });
};
const fetchSportkryssAction = drawNumber => dispatch => {
  dispatch({
    type: FETCH_SPORTKRYSS,
    drawNumber
  });
  services.fetchSportkryss(drawNumber, (error, response) => {
    if (error) {
      dispatch({
        type: FETCH_SPORTKRYSS_FAILURE,
        error
      });
    } else {
      dispatch({
        type: FETCH_SPORTKRYSS_SUCCESS,
        response
      });
    }
  });
};
const fetchDrawsByProductAction = productId => dispatch => {
  const productName = helpers.idToName[productId];
  dispatch({
    type: FETCH_PRODUCTS_DRAWS,
    productName
  });
  const fetchServices = {
    [productIds.RACING]: services.fetchRaces
  };
  const service = fetchServices[productId] || services.fetchDrawsByProduct;
  service(productName, (error, res) => {
    if (error) {
      dispatch({
        type: FETCH_PRODUCTS_DRAWS_FAILURE,
        error,
        errorMessage: "Vi kunde inte h\xE4mta spelomg\xE5ngarna, f\xF6rs\xF6k igen lite senare.",
        productName
      });
      return;
    }
    dispatch(fetchProductsDrawsSuccess(res, productName));
  });
};
const fetchSingleDrawAction = (productId, drawNumber) => (dispatch, getState) => {
  const productName = helpers.idToName[productId];
  const cachedDraw = selectors.draws.getDraw(getState(), {
    drawNumber,
    productName
  });
  const cachedDrawEvents = selectors.draws.getDrawEvents(getState(), {
    drawNumber,
    productName
  });
  if (cachedDraw && cachedDrawEvents) {
    return;
  }
  dispatch({
    type: FETCH_SINGLE_DRAW,
    productName,
    drawNumber
  });
  const fetchServices = {
    [productIds.RACING]: services.fetchSingleRaceDraw
  };
  const service = fetchServices[productId] || services.fetchSingleDraw;
  service(productId, drawNumber, (error, draw) => {
    if (error) {
      dispatch({
        type: FETCH_SINGLE_DRAW_FAILURE,
        productName,
        drawNumber,
        error
      });
      return;
    }
    dispatch(fetchSingleDrawSuccess(draw, productName, drawNumber));
  });
};
const FETCH_SINGLE_FORECAST = 'DRAWS/FETCH_SINGLE_FORECAST';
const FETCH_SINGLE_FORECAST_SUCCESS = 'DRAWS/FETCH_SINGLE_FORECAST_SUCCESS';
const FETCH_SINGLE_FORECAST_FAILURE = 'DRAWS/FETCH_SINGLE_FORECAST_FAILURE';
const fetchForecast = (productId, drawNumber) => dispatch => {
  dispatch({
    type: FETCH_SINGLE_FORECAST,
    productId,
    drawNumber
  });
  services.fetchForecast(productId, drawNumber, (err, forecast) => {
    if (err) {
      dispatch({
        type: FETCH_SINGLE_FORECAST_FAILURE,
        productId,
        drawNumber
      });
    } else {
      dispatch({
        type: FETCH_SINGLE_FORECAST_SUCCESS,
        productId,
        drawNumber,
        forecast
      });
    }
  });
};
const ADD_FORECAST = 'DRAWS/ADD_FORECAST';
function addForecast(productId, drawNumber, forecast) {
  return {
    type: ADD_FORECAST,
    productId,
    drawNumber,
    forecast: helpers.normalizeForecast(forecast)
  };
}
setGlobal('svs.components.marketplaceData.draws.actions', {
  FETCH_PRODUCTS_DRAWS,
  FETCH_PRODUCTS_DRAWS_FAILURE,
  FETCH_PRODUCTS_DRAWS_SUCCESS,
  fetchDrawsByProductAction,
  FETCH_ALL_DRAWS,
  FETCH_ALL_DRAWS_FAILURE,
  FETCH_ALL_DRAWS_SUCCESS,
  fetchAllDrawsAction,
  FETCH_SINGLE_DRAW,
  FETCH_SINGLE_DRAW_FAILURE,
  FETCH_SINGLE_DRAW_SUCCESS,
  fetchSingleDrawAction,
  FETCH_SINGLE_FORECAST,
  FETCH_SINGLE_FORECAST_SUCCESS,
  FETCH_SINGLE_FORECAST_FAILURE,
  fetchForecast,
  FETCH_SPORTKRYSS,
  FETCH_SPORTKRYSS_SUCCESS,
  FETCH_SPORTKRYSS_FAILURE,
  fetchSportkryssAction,
  ADD_FORECAST,
  addForecast
});

 })(window);