(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/draws/assets/javascripts/helpers/normalize-draw-event.js') >= 0) return;  svs.modules.push('/components/marketplace-data/draws/assets/javascripts/helpers/normalize-draw-event.js');
"use strict";


const {
  BOMBEN,
  FULLTRAFF,
  POWERPLAY
} = svs.utils.products.productIds;
const normalizeDrawEvent = (drawEvent, productId) => {
  let outcomes = drawEvent.outcomes;
  if (productId === BOMBEN) {
    outcomes = outcomes || drawEvent.match.participants.map(() => [{
      description: '0',
      outcomeNumber: 0
    }, {
      description: '1',
      outcomeNumber: 1
    }, {
      description: '2',
      outcomeNumber: 2
    }, {
      description: '3',
      outcomeNumber: 3
    }, {
      description: '4',
      outcomeNumber: 4
    }, {
      description: '5',
      outcomeNumber: 5
    }, {
      description: '6',
      outcomeNumber: 6
    }, {
      description: '7',
      outcomeNumber: 7
    }, {
      description: '8',
      outcomeNumber: 8
    }, {
      description: '9',
      outcomeNumber: 9
    }, {
      description: 'f',
      outcomeNumber: 10
    }]);
  } else if (productId === FULLTRAFF) {
    outcomes = outcomes || [...Array(8).keys()].map(value => ({
      description: "".concat(value).concat(value === 7 ? '+' : ''),
      outcomeNumber: value
    }));
  } else {
    outcomes = outcomes || [{
      description: '1',
      outcomeNumber: 1
    }, {
      description: 'X',
      outcomeNumber: 2
    }, {
      description: '2',
      outcomeNumber: 4
    }];
  }
  const participants = drawEvent.match ? drawEvent.match.participants : null;
  const matchStart = drawEvent.match ? drawEvent.match.matchStart : null;
  const participantNames = participants === null || participants === void 0 ? void 0 : participants.map(participant => participant === null || participant === void 0 ? void 0 : participant.mediumName).filter(Boolean).join(' - ');
  const isPowerplayH2H = productId === POWERPLAY && (drawEvent === null || drawEvent === void 0 ? void 0 : drawEvent.participantType) === 'Player';
  const comment = isPowerplayH2H ? 'Vem gör flest mål?' : drawEvent.eventComment;
  return {
    eventComment: comment,
    eventDescription: participantNames || drawEvent.eventDescription,
    eventTypeId: drawEvent.eventTypeId,
    eventNumber: drawEvent.eventNumber,
    matchParticipants: participants,
    participantType: drawEvent.participantType,
    matchStart,
    outcomes
  };
};
setGlobal('svs.components.marketplaceData.draws.helpers.normalizeDrawEvent', normalizeDrawEvent);

 })(window);