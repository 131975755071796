(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/draws/assets/javascripts/helpers/id-to-name.js') >= 0) return;  svs.modules.push('/components/marketplace-data/draws/assets/javascripts/helpers/id-to-name.js');
"use strict";


const {
  productIds
} = svs.utils.products;
const idToName = {
  [productIds.STRYKTIPSET]: 'stryktipset',
  [productIds.EUROPATIPSET]: 'europatipset',
  [productIds.T8]: 'topptipset',
  [productIds.T8EXTRA]: 'topptipset',
  [productIds.T8STRYK]: 'topptipset',
  [productIds.POWERPLAY]: 'powerplay',
  [productIds.BOMBEN]: 'bomben',
  [productIds.RACING]: 'hastar',
  [productIds.CHALLENGE]: 'challenge',
  [productIds.HVS]: 'fulltraff'
};
setGlobal('svs.components.marketplaceData.draws.helpers.idToName', idToName);

 })(window);