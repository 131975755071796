(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/draws/assets/javascripts/services/fetch-single-draw.js') >= 0) return;  svs.modules.push('/components/marketplace-data/draws/assets/javascripts/services/fetch-single-draw.js');
"use strict";


const {
  cachedJupiterGet
} = svs.components.marketplaceData;
const {
  helpers
} = svs.components.marketplaceData.draws;
const {
  getSpecificDrawName
} = svs.utils.products;
const fetchSingleDraw = (productId, drawNumber, callback) => {
  const specificDrawname = getSpecificDrawName(productId);
  const url = "/draw/1/".concat(specificDrawname, "/draws/").concat(drawNumber, "?include=").concat(encodeURIComponent(specificDrawname === 'bomben' ? helpers.bombenDrawInclude('draw') : helpers.drawInclude('draw')));
  fetchSingleDraw.cacheRequest(url, _ref => {
    let {
      draw
    } = _ref;
    return callback(undefined, {
      draws: [helpers.normalizeDraw(draw)]
    });
  }, error => callback(error.responseJSON.error));
};
fetchSingleDraw.cacheRequest = cachedJupiterGet.createCache(3000);
setGlobal('svs.components.marketplaceData.draws.services.fetchSingleDraw', fetchSingleDraw);

 })(window);