(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/draws/assets/javascripts/helpers/normalize-draw.js') >= 0) return;  svs.modules.push('/components/marketplace-data/draws/assets/javascripts/helpers/normalize-draw.js');
"use strict";

const _excluded = ["events"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }

const {
  products
} = svs.utils;
const {
  helpers
} = svs.components.marketplaceData.draws;
const {
  userProduct2SubProductId
} = svs.components.sport.tipsenShared;
const normalizeDraw = response => {
  const _ref = response || {},
    {
      events = []
    } = _ref,
    draw = _objectWithoutProperties(_ref, _excluded);
  const productName = products.getSpecificDrawName(draw.productId);
  const subProductId = draw.userProduct === draw.productName ? '' : userProduct2SubProductId[draw.userProduct];
  return {
    productId: draw.productId,
    drawNumber: draw.drawNumber,
    drawComment: draw.drawComment,
    description: draw.description,
    drawState: draw.drawState,
    drawStateId: draw.drawStateId,
    drawEvents: (draw.drawEvents || events).map(drawEvent => helpers.normalizeDrawEvent(drawEvent, draw.productId)),
    name: helpers.getFormattedName(draw, subProductId),
    productName,
    regCloseTime: new Date(draw.regCloseTime),
    subProductId,
    currentNetSale: draw.currentNetSale || draw.currentNetSales,
    matchCount: draw.matchCount
  };
};
setGlobal('svs.components.marketplaceData.draws.helpers.normalizeDraw', normalizeDraw);

 })(window);