(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/draws/assets/javascripts/services/fetch-races.js') >= 0) return;  svs.modules.push('/components/marketplace-data/draws/assets/javascripts/services/fetch-races.js');
"use strict";


const {
  jupiter
} = svs.core;
const {
  helpers
} = svs.components.marketplaceData.draws;
const {
  gameTypeToSubProductId,
  gameTypes
} = svs.racing.common.constants;
const TOPP5 = gameTypeToSubProductId[gameTypes.TOPP5];
const fetchRaces = (productName, callback) => {
  const url = "/racing/1/racecard/product/".concat(TOPP5, "?includeDefined=true");
  jupiter.get(url, response => {
    callback(undefined, helpers.normalizeRaceMarketplace(response));
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceData.draws.services.fetchRaces', fetchRaces);

 })(window);