(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/draws/assets/javascripts/services/fetch-all-draws.js') >= 0) return;  svs.modules.push('/components/marketplace-data/draws/assets/javascripts/services/fetch-all-draws.js');
"use strict";


const {
  jupiter
} = svs.core;
const {
  helpers
} = svs.components.marketplaceData.draws;
const {
  gameTypeToSubProductId,
  gameTypes
} = svs.racing.common.constants;
const {
  products: productResolver
} = svs.utils;
const {
  getProdName
} = svs.components.lbUtils.sportProducts;
const topptipset = getProdName(productResolver.productIds.T8);
const getDrawUrl = product => {
  const productMapper = {
    [topptipset]: 'topptipsetfamily'
  };

  const apiMapper = {
    hastar: "/racing/1/racecard/product/".concat(gameTypeToSubProductId[gameTypes.TOPP5], "?includeDefined=true")
  };
  product = productMapper[product] || productResolver.getDrawName(product) || product;
  const api = apiMapper[product] || "/draw/1/".concat(product, "/draws/light?include=").concat(encodeURIComponent(helpers.drawInclude('draws', {
    excludeEvents: true
  })));
  return api;
};
const fetchAllDraws = (productList, callback) => {
  const productUrls = productList.map(product => getDrawUrl(product.toLowerCase()));
  jupiter.get(productUrls, response => {
    const productsWithDraws = {};
    for (let i = 0; i < response.responses.length; ++i) {
      const product = response.responses[i];
      if (product.error) {
        callback(product.error);
        return;
      }
      if (productResolver.helpers.isRacing(productList[i])) {
        productsWithDraws[productList[i]] = helpers.normalizeRaceMarketplace(product);
      } else {
        productsWithDraws[productList[i]] = {
          draws: product.draws.filter(draw => draw.drawStateId === 2).map(draw => helpers.normalizeDraw(draw))
        };
      }
    }

    for (const productIdentifier of Object.keys(productsWithDraws)) {
      const product = productsWithDraws[productIdentifier];
      product.draws = product.draws.filter(draw => draw.regCloseTime >= new Date());
      if (productResolver.helpers.isRacing(productIdentifier)) {
        const meetingIds = new Set();
        const raceIds = new Set();
        for (const draw of product.draws) {
          meetingIds.add(product.races[draw.raceId].meetingId);
          raceIds.add(draw.raceId);
        }
        const meetingIdsToKeep = Object.keys(product.meetings).filter(meetingId => meetingIds.has(Number(meetingId)));
        const meetings = {};
        for (const meetingId of meetingIdsToKeep) {
          meetings[meetingId] = product.meetings[meetingId];
        }
        product.meetings = meetings;
        const raceIdsToKeep = Object.keys(product.races).filter(raceId => raceIds.has(Number(raceId)));
        const races = {};
        for (const raceId of raceIdsToKeep) {
          races[raceId] = product.races[raceId];
        }
        product.races = races;
      }
    }
    const _sortByTimeAsc = list => list.sort((a, b) => new Date(a.regCloseTime).getTime() - new Date(b.regCloseTime).getTime());
    Object.keys(productsWithDraws).forEach(key => _sortByTimeAsc(productsWithDraws[key].draws));
    callback(undefined, productsWithDraws);
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceData.draws.services.fetchAllDraws', fetchAllDraws);

 })(window);