(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/draws/assets/javascripts/services/fetch-sportkryss.js') >= 0) return;  svs.modules.push('/components/marketplace-data/draws/assets/javascripts/services/fetch-sportkryss.js');
"use strict";


const {
  jupiter
} = svs.core;
const logger = svs.core.log.getLogger('marketplace-data:draws');
const fetchSportkryss = (drawNumber, callback) => {
  if (!drawNumber) {
    logger.warn('Called fetchSportkryss service without supplying drawNumber');
    return;
  }
  const url = "/draw/1/sportkrysset/draws/".concat(drawNumber, "/result");
  jupiter.get(url, response => {
    const result = (response === null || response === void 0 ? void 0 : response.result) || {};
    callback(undefined, [result]);
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceData.draws.services.fetchSportkryss', fetchSportkryss);

 })(window);