(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/draws/assets/javascripts/services/fetch-racing-turnover.js') >= 0) return;  svs.modules.push('/components/marketplace-data/draws/assets/javascripts/services/fetch-racing-turnover.js');
"use strict";


const {
  cachedJupiterGet
} = svs.components.marketplaceData;
const fetchRacingTurnover = (raceId, userProduct, callback) => {
  const url = "/racing/1/race/".concat(raceId, "/turnover?userProduct=").concat(userProduct);
  fetchRacingTurnover.cacheRequest(url, response => callback(undefined, response.turnover), error => callback(error.responseJSON.error));
};
fetchRacingTurnover.cacheRequest = cachedJupiterGet.createCache(3000);
setGlobal('svs.components.marketplaceData.draws.services.fetchRacingTurnover', fetchRacingTurnover);

 })(window);