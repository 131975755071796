(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/draws/assets/javascripts/helpers/get-formatted-name.js') >= 0) return;  svs.modules.push('/components/marketplace-data/draws/assets/javascripts/helpers/get-formatted-name.js');
"use strict";


const {
  isTopptipset,
  isTopptipsetStryk,
  isTopptipsetExtra,
  isMaltipset,
  isBomben,
  isChallenge
} = svs.utils.products.helpers;
const {
  productIds,
  isTipsetProduct
} = svs.utils.products;
const {
  getWeek
} = dateFns;
const {
  getProductName
} = svs.racing.common.strings;
const {
  parseGameType
} = svs.racing.raceService.utils.parse;
const {
  getSubProductDisplayName
} = svs.components.sport.tipsenShared;
function getFormattedName(draw, subProductId) {
  let drawName = '';
  if (isTopptipset(draw.productId)) {
    if (isTopptipsetStryk(draw.productId)) {
      drawName = "Stryk V ".concat(getWeek(new Date(draw.regCloseTime), {
        locale: dateFns.locales.sv
      }));
    } else if (isTopptipsetExtra(draw.productId)) {
      drawName = "Europa V ".concat(getWeek(new Date(draw.regCloseTime), {
        locale: dateFns.locales.sv
      }));
    } else {
      drawName = "V ".concat(getWeek(new Date(draw.regCloseTime), {
        locale: dateFns.locales.sv
      }));
    }
  } else if (isTipsetProduct(draw.productId)) {
    const prefix = getSubProductDisplayName(subProductId);
    drawName = "".concat(prefix, " V ").concat(getWeek(new Date(draw.regCloseTime), {
      locale: dateFns.locales.sv
    })).trim();
  } else if (isMaltipset(draw.productId)) {
    drawName = "V ".concat(getWeek(new Date(draw.regCloseTime), {
      locale: dateFns.locales.sv
    }));
  } else if (isBomben(draw.productId)) {
    drawName = draw.drawComment;
  } else if (isChallenge(draw.productId)) {
    drawName = draw.drawComment;
  } else if (draw.productId === productIds.RACING) {
    drawName = getProductName(parseGameType(draw));
  }
  return drawName;
}
setGlobal('svs.components.marketplaceData.draws.helpers.getFormattedName', getFormattedName);

 })(window);