(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/draws/assets/javascripts/helpers/bomben-draw-include.js') >= 0) return;  svs.modules.push('/components/marketplace-data/draws/assets/javascripts/helpers/bomben-draw-include.js');
"use strict";


const _drawIncludes = ['drawNumber', 'regCloseTime', 'productId', 'drawComment', 'description', 'drawState', 'drawStateId', 'currentNetSales', 'matchCount'];
const _drawEventIncludes = ['drawEvents.eventTypeId', 'drawEvents.eventNumber', 'drawEvents.eventDescription', 'drawEvents.outcomes.outcomeNumber', 'drawEvents.outcomes.description', 'drawEvents.participantType', 'drawEvents.match.participants.h2HName', 'drawEvents.match.matchStart', 'events.eventNumber', 'events.eventDescription', 'events.outcomes.outcomeNumber', 'events.outcomes.description', 'events.match.matchStart', 'events.match.participants.mediumName'];
const getIncludes = _ref => {
  let {
    excludeEvents = false
  } = _ref;
  return excludeEvents ? _drawIncludes : [..._drawIncludes, ..._drawEventIncludes];
};
const bombenDrawInclude = function (parent) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return getIncludes(options).map(include => "".concat(parent, ".").concat(include)).join(',');
};
setGlobal('svs.components.marketplaceData.draws.helpers.bombenDrawInclude', bombenDrawInclude);

 })(window);