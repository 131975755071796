(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/draws/assets/javascripts/helpers/get-forecast-url.js') >= 0) return;  svs.modules.push('/components/marketplace-data/draws/assets/javascripts/helpers/get-forecast-url.js');
"use strict";


const {
  getSpecificDrawName
} = svs.utils.products;
function getForecastUrl(productId, drawNumber) {
  const specificDrawname = getSpecificDrawName(productId);
  return "/draw/1/".concat(specificDrawname, "/draws/forecast/").concat(drawNumber);
}
setGlobal('svs.components.marketplaceData.draws.helpers.getForecastUrl', getForecastUrl);

 })(window);