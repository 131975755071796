(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/draws/assets/javascripts/services/fetch-draws-by-product.js') >= 0) return;  svs.modules.push('/components/marketplace-data/draws/assets/javascripts/services/fetch-draws-by-product.js');
"use strict";


const {
  jupiter
} = svs.core;
const {
  products
} = svs.utils;
const {
  helpers
} = svs.components.marketplaceData.draws;
const fetchDrawsByProduct = (productDrawName, callback) => {
  if (!productDrawName) {
    throw new Error('Called fetchDrawsByProduct service without supplying a product name!');
  }
  const drawName = products.getDrawName(productDrawName);

  const url = "/draw/1/".concat(drawName, "/draws?include=").concat(encodeURIComponent(productDrawName === 'bomben' ? helpers.bombenDrawInclude('draws', {
    productname: productDrawName
  }) : helpers.drawInclude('draws', {
    productname: productDrawName
  })));
  jupiter.get(url, response => {
    callback(undefined, {
      draws: response.draws.filter(draw => draw.drawStateId === 2).map(helpers.normalizeDraw)
    });
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceData.draws.services.fetchDrawsByProduct', fetchDrawsByProduct);

 })(window);